<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Loan Equipment" backTo="internal">
    </page-header>

    <div class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <!-- Toolbar with Tab Headers and Search Controls -->
        <div class="flex justify-between items-center mb-4">
          <TabHeaders :tabs="tabs" :selectedTab="currentTab" @tab-selected="onTabSelected" />

          <div class="flex flex-1 justify-end">
            <input type="text" class="ml-10 w-full lg:w-1/2 rounded-l bge-input bge-input-spacing" placeholder="Search "
              v-model="searchInput" @keydown.enter="triggerSearch" />
            <button @click="triggerSearch"
              class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out">
              <Icon iconType="search" />
            </button>
          </div>
        </div>

        <!-- Tab Content -->
        <!-- v-for="tab in tabs" v-if="tab.id===currentTab" -->
        <DataTableComponent :columns="columns" storeName="loanEquipment" @row-click="onRowClick">
          <template v-slot:date-slot="{ rowData }">
            <span>
              {{ rowData.loan_equipment ? formatStartAt(rowData.loan_equipment.start_at) : "" }}
            </span>
          </template>
          <template v-slot:custom-actions-slot="{ rowData }">
            <div class="flex justify-end items-center w-full">
              <button type="button" @click="Edit(rowData)"
                class="ml-2 bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1">
                <Icon iconType="edit" />
              </button>
              <button type="button" @click="Delete(rowData)"
                class="ml-2 bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1">
                <!-- <Icon iconType="delete" /> -->
                <svg class="stroke-current h-5 w-5" fill="none" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>
              </button>
            </div>
          </template>
        </DataTableComponent>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Spinner from "@/components/Spinner.vue";
import Panel from "@/components/Panel.vue";
import TabHeaders from "@/components/TabHeaders.vue";
import TabContent from "@/components/TabContent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { mapActions, mapState } from "vuex";
import Icon from "@/components/Icon.vue";
// import De from "vuejs-datepicker/dist/locale/translations/de";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [notificationMixin],
  components: {
    PageHeader,
    Spinner,
    Panel,
    IsAuthorized,
    TabHeaders,
    TabContent,
    DataTableComponent,
    Icon
  },
  data() {
    return {
      tabs: [
        {
          id: "all",
          label: "All",
        },
        { id: "customer", label: "At Customer" },
        { id: "instock", label: "In Stock" },
        { id: "repair", label: "Under Service / Repair" },
        // { id: "search", label: "Search", iconType: "search" },
      ],
      columns: [
        {
          field: "product_code",
          header: "Code",
          sortable: true,
          custom: false,
          width: '10%',
        },
        {
          field: "description",
          header: "Description",
          sortable: true,
          custom: false,
          width: '20%',
        },
        {
          field: "serial_number",
          header: "Serial Number",
          sortable: true,
          custom: false,
          width: '20%',
        },
        {
          field: "loan_equipment.status",
          header: "Location",
          sortable: true,
          custom: false,
          width: '20%',
        },
        {
          field: "previous_loan_equipment.status",
          header: "Previous Location",
          sortable: true,
          custom: false,
          width: '20%',
        }, {
          field: "loan_equipment.start_at",
          header: "Date Sent",
          sortable: true,
          custom: true,
          slotName: "date-slot",
          width: '10%',
        },
        {
          field: "actions",
          header: "",
          sortable: false,
          custom: true,
          slotName: "custom-actions-slot",
          width: '10%',
        },
      ],
      storeName: "loanEquipment",
      locationPanelTitle: '',
      selectedEquipment: null,
      isLocationEdit: false
    };
  },
  computed: {
    ...mapState("loanEquipment", ["currentTab", "searchText", "selectedCategory"]),
    searchInput: {
      get() {
        return this.$store.state.loanEquipment.tabs[this.$store.state.loanEquipment.currentTab] ? this.$store.state.loanEquipment.tabs[this.$store.state.loanEquipment.currentTab].searchText : "";
      },
      set(value) {
        this.$store.commit("loanEquipment/UPDATE_SEARCH_TEXT", value);
      },
    },
  },
  mounted() {
    this.fetchTableData();
  },
  methods: {
    // De,
    ...mapActions("loanEquipment", [
      "fetchTableData",
      "updateSearchCriteria",
      "updateTab",
    ]),

    onTabSelected(newTab) {
      this.$store.dispatch("loanEquipment/updateTab", newTab);
    },
    triggerSearch() {
      // this.$store.dispatch("loanEquipment/updateSearchCriteria", {
      //   searchText: this.searchText,
      //   selectedCategory: this.selectedCategory,
      // });
      this.fetchTableData();
    },
    updateSearch() { },
    handleRowSelect(rowObj) {

    },
    Confirm(rowObj) {

    },
    Edit(rowObj) {
      this.locationPanelTitle = "Edit Location"
      this.selectedEquipment = rowObj;
      this.isLocationEdit = true;
      this.showingLocationPanel = true;
    },
    async Delete(rowObj) {
      const confirmed = await this.confirmAction({
        message: "Do you want to detach this record?",
      });

      if (!confirmed) {
        return;
      }

      try {
        const status = await this.EquipmentService.updateLoanStatus(
          rowObj.equipment_id, false
        );
        if (status.data) {
          this.notifySuccess("Record detached successfully.");
          this.fetchTableData();
        } else {
          this.notifyError('Error on detaching the record')
        }
      } catch (error) {
        this.notifyError('Error on detaching the record')
      }

    }, formatStartAt(start_at) {
      return start_at == 0 || start_at == null ? "" : this.$moment.unix(start_at).format("DD-MM-YYYY");
    },
    onRowClick(event) {
      this.Edit(event.data);
    },
    Edit(rowObj) {
      this.$router.push({
        name: "loan-equipment-edit",
        params: {
          loanEquipmentId: rowObj.equipment_id,
        },
      });
    }
  },
};
</script>

<template>
  <div>
    <DataTable ref="myDataTable" class="mt-5" stripedRows :value="tableData"
      :sortField="currentSortField" :sortOrder="currentSortOrder" :paginator="true" :rows="rowsPerPage"
      :totalRecords="totalRecords" selectionMode="single" @row-select="onRowSelect" @row-click="onRowClick"
      @page="onPageChange" @sort="onSort" responsiveLayout="scroll"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[1, 2, 5, 10, 20]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      :lazy="true" :loading="loading">
      <!-- Standard columns -->
      <Column v-for="col in columns" :key="col.field" :field="col.field" :header="col.header" :sortable="col.sortable"
        :headerStyle="{ width: col.width }">
        <!-- Custom slot content within Column if needed -->
        <template v-if="col.custom" #body="slotProps">
          <slot :name="col.slotName" :row-data="slotProps.data" />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      paginator: null,
      isSystemUpdatedPage: false
    };
  },
  mounted() {
    const myDataTable = this.$refs.myDataTable;
    this.paginator = myDataTable.$children.filter((c) => c.$options._componentTag === 'DTPaginator')[0];
  },
  watch: {

  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    storeName: {
      type: String,
      required: true,
      default: "loanEquipment"
    }
  },
  computed: {
    ...mapState({
      currentTab(state) {
        return state[this.storeName].tabs[state[this.storeName].currentTab];
      },
      tableData(state) {
        if (this.paginator && this.paginator.currentPage != 0 && this.currentPage != this.paginator.currentPage) {
          this.changePageProgrammatically(this.currentPage);
        }
        return this.currentTab ? this.currentTab.tableData : [];
      },
      totalRecords(state) {
        return this.currentTab ? this.currentTab.totalRecords : 0;
      },
      rowsPerPage(state) {
        return this.currentTab ? this.currentTab.rowsPerPage : 10;
      },
      currentPage(state) {
        return this.currentTab ? this.currentTab.currentPage : 1;
      },
      currentSortField(state) {
        return this.currentTab ? this.currentTab.sortField : "";
      },
      currentSortOrder(state) {
        return this.currentTab ? this.currentTab.sortOrder : 1;
      },
      loading(state) {
        return this.currentTab ? this.currentTab.isloading : false;
      },
    })
  },
  methods: {
    ...mapActions({
      updateTableSettings(dispatch, payload) {
        return dispatch(this.storeName + '/updateTableSettings', payload)
      },
      updateSortOptions(dispatch, payload) {
        return dispatch(this.storeName + '/updateSortOptions', payload)
      }
    }),
    onRowSelect(event) {
      this.$emit("row-select", event);
    },
    onRowClick(event) {
      this.$emit("row-click", event);
    },
    onPageChange(event) {
      if (!this.isSystemUpdatedPage) {
        this.updateTableSettings({
          rows: event.rows,
          page: event.page
        });
      }
      this.isSystemUpdatedPage = false;
    },
    onSort(event) {
      this.updateSortOptions({
        field: event.sortField,
        order: event.sortOrder
      });
    },
    changePageProgrammatically(page) {
      this.isSystemUpdatedPage = true;
      this.paginator.changePage(page - 1);
    },
  },
};
</script>
